<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CButton variant="outline" type="submit" color="primary" class="new-booking-btn"
              @click="$router.push({ path: '/manager/bookings/create' })">
              <CIcon name="cil-check-circle" />New booking
            </CButton>
          </CCol>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Details of Booking #{{ booking.id }}</CCardTitle>
                <div class="card-header-actions">
                  <CButton variant="outline" type="submit" color="success" @click="downloadContract">
                    <CIcon name="cil-cloud-download" />PDF Contract
                  </CButton>
                </div>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6" v-if="$store.getters.isAdmin">
                    <CSelect label="Location" placeholder="Select location" :options="locations"
                      :value.sync="booking.location_id" v-on:update:value="locationChanged()" />
                  </CCol>
                  <CCol sm="6">
                    <label>Customer</label>
                    <v-select v-if="booking.customer_id" v-model="booking.customer_id" :reduce="(customer) => customer.id"
                      label="name" :options="customers"></v-select>
                    <p v-else>
                      {{ booking.mews_customer_name }} (Mews)
                    </p>
                  </CCol>
                  <CCol sm="12">
                    <CTextarea name="note" placeholder="Enter a note"
                      description="Enter a note for the booking. E.g. cancelled booking." v-model="booking.note" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Booking Rows</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="12" class="add-btn">
                    <CButton v-if="$store.getters.isLocationAdmin" variant="outline" type="submit" color="warning"
                      @click="addBookingRow()">
                      <CIcon name="cil-playlist-add" />Add Row
                    </CButton>
                  </CCol>

                  <CContainer v-for="(booking_row, index) in booking.booking_rows" id="bookingRowsContainer">
                    <hr />
                    <CInput name="id" type="hidden" v-model="booking_row.id" disabled />
                    <CRow>
                      <CCol md="1" sm="1">
                        <CCol sm="12" class="close-btn-container">
                          <CButton variant="outline" type="submit" size="sm" color="danger"
                            @click="deleteBookingRow(index)" v-if="$store.getters.isAdmin ||
                              typeof booking_row.id != 'number'
                              ">
                            <CIcon class="close-btn" name="cil-trash" />
                          </CButton>
                        </CCol>
                      </CCol>
                      <CCol sm="11">
                        <CRow>
                          <CCol md="4" sm="5">
                            <label class="datetime-label">From</label>
                            <datetime format="dd-MM-yyyy HH:mm" type="datetime" value-zone="UTC" zone="UTC"
                              placeholder="Date and time" label="From" v-model="booking_row.from"
                              v-on:input="getAvailableProducts(index)" auto :disabled="!$store.getters.isAdmin &&
                                !$store.getters.isLocationElite &&
                                typeof booking_row.id == 'number'
                                "></datetime>
                          </CCol>

                          <CCol md="4" sm="5">
                            <label class="datetime-label">Till</label>
                            <datetime format="dd-MM-yyyy HH:mm" type="datetime" value-zone="UTC" zone="UTC"
                              placeholder="Date and time" v-model="booking_row.till"
                              v-on:input="getAvailableProducts(index)" auto :disabled="!$store.getters.isAdmin &&
                                typeof booking_row.id == 'number'
                                "></datetime>
                          </CCol>

                          <CCol md="4" sm="5">
                            <label class="datetime-label">Returned At</label>
                            <datetime auto format="dd-MM-yyyy HH:mm" type="datetime" placeholder="Date and time"
                              v-model="booking_row.returned_at" v-on:input="getAvailableProducts(index)" :disabled="!$store.getters.isAdmin &&
                                !$store.getters.isLocationElite
                                "></datetime>
                          </CCol>

                          <CCol md="4" sm="4">
                            <CSelect label="Product" name="product" placeholder="Select Product"
                              :options="booking_row.availableProducts" :value.sync="booking_row.product_id"
                              v-on:update:value="productSelected(booking_row)" v-on:input="productSelected(booking_row)"
                              :disabled="!$store.getters.isAdmin &&
                                !$store.getters.isLocationElite &&
                                typeof booking_row.id == 'number'
                                " />
                          </CCol>

                          <CCol md="2" sm="4">
                            <CInput name="discount" label="Discount" type="number" placeholder="0" prepend="€"
                              v-model="booking_row.discount" :disabled="!$store.getters.isAdmin"
                              v-on:input="doCalculations" min="0" />
                          </CCol>

                          <CCol md="2" sm="4">
                            <CInput name="price" label="Price" type="number" placeholder="Price" prepend="€" min="0"
                              v-model="booking_row.price_inc" disabled />
                          </CCol>

                          <CCol sm="1">
                            <label for="" class="">Insurance</label><br />
                            <CSwitch label="Insurance" v-bind="{
                              variant: '3d',
                              shape: 'pill',
                              size: 'lg',
                            }" color="success" :checked.sync="booking_row.insurance"
                              v-on:update:checked="doCalculations" variant="outline"
                              :disabled="!booking_row.insurance_optional" />
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CContainer>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Summary</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CContainer>
                    <CRow>
                      <CCol sm="2">
                        <p>Price ex.</p>
                        € <strong>{{ summary.priceEx }}</strong>
                      </CCol>
                      <CCol sm="2">
                        <p>Tax</p>
                        € <strong>{{ summary.tax }}</strong>
                      </CCol>
                      <CCol sm="2">
                        <p>Price inc.</p>
                        € <strong>{{ summary.priceInc }}</strong>
                      </CCol>
                    </CRow>
                  </CContainer>
                </CRow>
              </CCardBody>
            </CCard>

            <CRow class="terms-conditions-block">
              <CCol sm="4">
                <CButton variant="outline" type="submit" color="primary" @click="showTerms = true">
                  <CIcon name="cil-file" />Show Terms & Conditions
                </CButton>
              </CCol>
              <CModal title="Terms & Conditions" color="primary" :show.sync="showTerms">
                <span v-html="location.terms"></span>
                <template #footer>
                  <CButton @click="
                    showTerms = false;
                  booking.terms_agreed = true;
                  " color="success">I agree</CButton>
                  <CButton @click="
                    showTerms = false;
                  booking.terms_agreed = false;
                  " color="danger">I do not agree</CButton>
                </template>
              </CModal>
            </CRow>
          </CCol>

          <CCol>
            <CButton v-if="$store.getters.isLocationAdmin || $store.getters.isLocationElite || $store.getters.isAdmin"
              variant="outline" type="submit" color="primary" @click="updateBooking">
              <CIcon name="cil-check-circle" />Update
            </CButton>
            <CButton v-if="$store.getters.isAdmin" class="float-right" variant="outline" type="submit" color="danger"
              @click="deleteBooking">
              <CIcon name="cil-trash" /> Delete
            </CButton>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import { Datetime } from "vue-datetime"
import "vue-datetime/dist/vue-datetime.css"

import BookingAPI from "/app/src/api/booking.js"
import CustomerAPI from "/app/src/api/customer.js"
import LocationAPI from "/app/src/api/location.js"
import ProductAPI from "/app/src/api/product.js"
import OrderAPI from "/app/src/api/booking.js"

export default {
  name: "Detail",
  components: { Datetime },
  data: () => {
    return {
      bookingAPI: new BookingAPI(),
      customerAPI: new CustomerAPI(),
      locationAPI: new LocationAPI(),
      productAPI: new ProductAPI(),
      bookingAPI: new BookingAPI(),
      customers: [],
      locations: [],
      location: {},
      booking: {
        booking_rows: [],
        price_discount: 0,
      },
      products: [],
      summary: {
        priceEx: 0,
        priceTax: 0,
        priceInc: 0,
      },
      showTerms: false,
    }
  },
  created: function () {
    this.getBooking(this.$route.params.id)
  },
  methods: {
    locationChanged() {
      this.getCustomers()
      this.getLocationDetails(this.booking.location_id)
      this.booking.booking_rows = []
      this.addBookingRow()
    },

    getLocations() {
      this.$store.dispatch("loading")
      let self = this
      this.locationAPI
        .all(this.booking.location_id)
        .then((locations) => {
          for (let i = 0; i < locations.length; i++) {
            self.locations.push({
              label: locations[i].name,
              value: locations[i].id,
            })
          }
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({ type: "danger", message: error })
        })
    },

    getLocationDetails(location_id) {
      this.$store.dispatch("loading")
      let self = this
      this.locationAPI
        .detail(location_id)
        .then((location) => {
          this.$store.dispatch("stopLoading")
          self.location = location
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({ type: "danger", message: error })
        })
    },

    getBooking: function (id) {
      this.$store.dispatch("loading")
      let self = this
      self.bookingAPI
        .detail(id)
        .then((booking) => {
          this.$store.dispatch("stopLoading")
          self.booking = booking
          this.getLocationDetails(booking.location_id)
          this.getCustomers()
          this.getLocations()
          this.calculateTotalPricing()
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({ type: "danger", message: error })
        })
    },

    getAvailableProducts: function (index) {
      let row = this.booking.booking_rows[index]
      if (row.from != "" && row.till != "") {
        this.$store.dispatch("loading")
        let self = this

        // IF returned_at is set, replace till with returned at
        let till = row.till
        if (row.returned_at != "" && typeof row.returned_at != "undefined") {
          till = row.returned_at
        }

        var availableProducts = []

        this.productAPI
          .availableProducts(
            row.from,
            till,
            this.booking.location_id,
            this.booking.id
          )
          .then((products) => {
            for (let i = 0; i < products.length; i++) {
              availableProducts.push({
                label: products[i].type + ": #" + products[i].number,
                value: products[i].id,
                product_id: products[i].id,
                price: products[i].price,
              })
            }
            this.$set(
              this.booking.booking_rows[index],
              "availableProducts",
              availableProducts
            )
            self.$store.dispatch("stopLoading")
          })
          .catch((error) => {
            this.$store.dispatch("stopLoading")
            this.$alert.show({ type: "danger", message: error })
          })
      }
    },

    getCustomers: function () {
      this.$store.dispatch("loading")
      let self = this
      this.customerAPI
        .byLocationId(self.booking.location_id)
        .then((customers) => {
          self.customers = customers
          this.$store.dispatch("stopLoading")
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
        })
    },

    addBookingRow: function () {
      var fromDate = new Date()
      var tillDate = new Date()

      // Create the till date
      let [hours, minutes, seconds] = this.location.return_time.split(":")
      tillDate.setHours(hours)
      tillDate.setMinutes(minutes)
      tillDate.setSeconds(seconds)

      if (fromDate > tillDate) {
        tillDate.setDate(tillDate.getDate() + 1)
      }

      var pad = function (i) {
        return i < 10 ? "0" + i : i
      }

      var fY = fromDate.getFullYear()
      var fm = fromDate.getMonth() + 1
      var fD = fromDate.getDate()
      var fH = fromDate.getHours()
      var fM = fromDate.getMinutes()
      var fS = fromDate.getSeconds()
      var fromDateString =
        fY +
        "-" +
        pad(fm) +
        "-" +
        pad(fD) +
        "T" +
        pad(fH) +
        ":" +
        pad(fM) +
        ":" +
        pad(fS)

      var tY = tillDate.getFullYear()
      var tm = tillDate.getMonth() + 1
      var tD = tillDate.getDate()
      var tH = tillDate.getHours()
      var tM = tillDate.getMinutes()
      var tS = tillDate.getSeconds()
      var tillDateString =
        tY +
        "-" +
        pad(tm) +
        "-" +
        pad(tD) +
        "T" +
        pad(tH) +
        ":" +
        pad(tM) +
        ":" +
        pad(tS)

      this.booking.booking_rows.push({
        product_id: null,
        from: fromDateString,
        till: tillDateString,
        insurance: true,
        discount: 0,
      })
    },

    updateBooking: function () {
      if (!this.booking.terms_agreed) {
        this.$alert.show({
          type: "danger",
          message: "Terms & Conditions haven't been agreed to",
        })
        return
      }

      this.$store.dispatch("loading")

      this.bookingAPI
        .update(this.booking)
        .then((result) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({
            type: "success",
            message: "The booking has been updated",
          })
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({ type: "danger", message: error })
        })
    },

    deleteBooking: function () {
      const params = {
        title: "Confirm",
        text: "Are you sure you want to delete?",
        onConfirm: () => {
          this.$store.dispatch("loading")
          this.bookingAPI
            .delete(this.$route.params.id)
            .then((result) => {
              this.$store.dispatch("stopLoading")
              this.$router.push({ path: "/manager/bookings" })
            })
            .catch((error) => {
              this.$store.dispatch("stopLoading")
              this.$alert.show({ type: "danger", message: error })
            })
        },
      }
      this.$confirm.show(params)
    },

    deleteBookingRow(index) {
      this.booking.booking_rows.splice(index, 1)
      this.calculateTotalPricing()
    },

    downloadContract: function () {
      this.$store.dispatch("loading")
      let self = this
      self.bookingAPI
        .downloadContract(self.booking.id)
        .then((booking) => {
          this.$store.dispatch("stopLoading")
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({ type: "danger", message: error })
        })
    },

    productSelected: function (booking_row) {
      console.log("productSelected")
      // Get the selected product
      for (let i = 0; i < booking_row.availableProducts.length; i++) {
        if (
          booking_row.availableProducts[i].product_id == booking_row.product_id
        ) {
          let selected_product = booking_row.availableProducts[i]

          // Existing row, override setting
          if (booking_row.id) {
            booking_row.insurance_optional = selected_product.price
              .insurance_optional
              ? true
              : false
          } else if (selected_product.price.insurance > 0) {
            booking_row.insurance = true
            booking_row.insurance_optional = selected_product.price
              .insurance_optional
              ? true
              : false
          } else {
            booking_row.insurance = false
            booking_row.insurance_optional = false
          }
          // End loop
          i = booking_row.availableProducts.length
        }
      }

      this.doCalculations()
    },

    doCalculations: function () {
      this.calculateBookingRowPricing()
      this.calculateTotalPricing()
    },

    calculateBookingRowPricing: function () {
      // Update all price fields for all order rows
      for (var i = 0; i < this.booking.booking_rows.length; i++) {
        // Reset calculations of row
        this.booking.booking_rows[i].tax_percentage = 0
        this.booking.booking_rows[i].price_inc = 0
        this.booking.booking_rows[i].price_tax = 0
        this.booking.booking_rows[i].price_ex = 0

        // Check if availble products are already fetched. Otherwise price calculation is not necessary
        if (!this.booking.booking_rows[i].hasOwnProperty("availableProducts")) {
          continue
        }

        // Set Booking Row Price
        for (
          var j = 0;
          j < this.booking.booking_rows[i].availableProducts.length;
          j++
        ) {
          // Compare if the selected product is found
          if (
            this.booking.booking_rows[i].availableProducts[j].product_id ===
            this.booking.booking_rows[i].product_id
          ) {
            // Update the booking row pricing
            this.booking.booking_rows[i].tax_percentage =
              this.booking.booking_rows[i].availableProducts[
                j
              ].price.tax_percentage
            this.booking.booking_rows[i].price_inc =
              this.booking.booking_rows[i].availableProducts[j].price.inc -
              this.booking.booking_rows[i].discount

            // Add insurance, if selected
            if (this.booking.booking_rows[i].insurance) {
              this.booking.booking_rows[i].price_inc +=
                this.booking.booking_rows[i].availableProducts[
                  j
                ].price.insurance
            }

            // Set tax and ex price
            this.booking.booking_rows[i].price_tax = this.formatNumber(
              (this.booking.booking_rows[i].price_inc /
                (this.booking.booking_rows[i].tax_percentage + 100)) *
              this.booking.booking_rows[i].tax_percentage,
              2
            )
            this.booking.booking_rows[i].price_ex = this.formatNumber(
              this.booking.booking_rows[i].price_inc -
              this.booking.booking_rows[i].price_tax,
              2
            )
          }
        }
      }
    },

    calculateTotalPricing: function () {
      // Define summary totals
      this.summary.priceEx = 0.0
      this.summary.tax = 0.0
      this.summary.priceInc = 0.0

      // Update all price fields for all order rows
      for (var i = 0; i < this.booking.booking_rows.length; i++) {
        // Update totals
        this.summary.priceEx += this.booking.booking_rows[i].price_ex
        this.summary.tax += this.booking.booking_rows[i].tax
        this.summary.priceInc += this.booking.booking_rows[i].price_inc
      }

      this.summary.priceEx = this.formatNumber(this.summary.priceEx, 2)
      this.summary.tax = this.formatNumber(this.summary.tax, 2)
      this.summary.priceInc = this.formatNumber(this.summary.priceInc, 2)
    },

    formatNumber(val, decimals) {
      //Parse the value as a float value
      val = parseFloat(val)
      //Format the value w/ the specified number
      //of decimal places and return it.
      return parseFloat(val.toFixed(decimals))
    },
  },

  watch: {},
}
</script>

<style scoped>
.close-btn-container {
  text-align: center;
}

.close-btn {
  margin-right: 0;
  margin-bottom: 0;
  margin-top: -2px;
  line-height: 14px;
}

.add-btn {
  margin-bottom: 20px !important;
}

.summary {
  margin-bottom: 20px;
}

#bookingRowsContainer .btn-outline-danger {
  margin-top: 32px;
  padding: 4px 8px;
}

#bookingRowsContainer div {
  padding: 2px;
}

.datetime-label {
  margin-bottom: 8px;
}

.new-booking-btn {
  margin-bottom: 20px;
}

.c-switch {
  margin-top: 3px;
  margin-left: 5px;
}

.terms-conditions-block {
  margin-bottom: 20px;
}
</style>
